import React from "react"
import Layout from "../components/layout/layout"
import { Button } from "@material-ui/core"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="404" />
    <div style={{margin: "38vh auto", width: "100%", textAlign: "center"}}>
  		<h2 style={{color: "#fff"}}>Under Construction !</h2>
  		<Button variant="outlined" style={{border: "2px solid white", borderRadius: "0px"}} href="/">Go Back Home</Button>
  	</div>
  </Layout>
)
